import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.allgoodnasiya.uz/' }),
  endpoints: (builder) => ({
    requestJobIdForm: builder.mutation({
      query: ({requestData}) => ({
        url: 'application/myid-identify',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(requestData)
      }),
    }),

  }),
});

export const { useRequestJobIdFormMutation  } = api;
export default api;
