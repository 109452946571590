import styled from 'styled-components'

export const MyIdWrapper = styled.div`
width: 100%;

`
export const WrapperError = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3{
        font-size: 20px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        color: gray;
        text-align: center;
    }
    svg{
        font-size: 120px;
        margin-top: 20px;
        color: rgb(254, 150, 50)!important;
    }
    button{
        background-color: rgb(254, 150, 50);
        border: none;
        color: white;
        font-size: 18px;
        padding: 10px 30px;
        border-radius: 12px;
        cursor: pointer;
    }
    h2{
    color: rgba(47, 43, 61, 0.78);
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}
h5{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    color: gray;
    opacity: 0.7;
    margin-bottom: 10px;
}
@media only screen and (max-width:600px) {
    h2{
    color: rgba(47, 43, 61, 0.78);
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}    
}
`

export const WrapperIframe = styled.div`

`