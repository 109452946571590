import styled from "styled-components";

export const Wrapper = styled.div`
  width: 98%;
  display: flex;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (max-width: 525px) {
    margin: 0px;
  }
`;

export const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  width: auto;
  border-right: 1px solid rgba(47, 43, 61, 0.16);
  @media screen and (max-width: 1024px) {
  border-right: 1px solid transparent;
    border-bottom: 1px solid rgba(47, 43, 61, 0.16);
    display: none;
  }
  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: transparent;
    padding: 10px;
    background-color: transparent;
    text-align: left;
    white-space: pre;
    cursor: pointer;
    &.active {
      span.icon {
        background-color: rgb(255, 159, 67);
        box-shadow: rgba(47, 43, 61, 0.14) 0px 3px 8px 0px;
        border-radius: 10px;
        svg {
          color: white;
        }
      }
    }
  }
  span.icon {
    background-color: rgb(219, 218, 222);
    box-shadow: rgba(47, 43, 61, 0.14) 0px 3px 8px 0px;
    border-radius: 10px;
    svg {
      color: rgba(47, 43, 61, 0.68);
      font-size: 30px;
      padding: 10px;
    }
    img {
      padding: 10px;
    }
  }
  span:nth-child(2) {
    margin-left: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    color: rgba(47, 43, 61, 0.78);
    font-size: 1.1rem;
  }
`;

export const WrapperRight = styled.div`
  padding: 1.5rem;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: auto;
    padding:0px
  }
`;

export const ButtonNewOrder = styled.button`
  background-color: rgb(254, 150, 50) !important;
  color: white !important;
  display: flex !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 15px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  margin-top: 20px !important;
  box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px !important;
`;
