import {
  getFromLocalStorage,
  removeLocalStorageItem,
  setToLocalStorage,
} from "./localStorage";
import DateFormate from "./dateFormate";
import { formatPhoneNumber } from "./formatPhoneNumber";
import { handlePassportInput } from "./passpor-seria";
import { handlePassportPnflInput } from "./pnfl-seria";
import { generateSecretKey  , decryptBaseUrl , encryptBaseUrl } from "./generateSecretKey";
import { getApplicationId } from "./api";
const Lib = {
  setToLocalStorage,
  getFromLocalStorage,
  removeLocalStorageItem,
  formatPhoneNumber,
  handlePassportInput,
  handlePassportPnflInput,
  decryptBaseUrl,
  encryptBaseUrl,
  DateFormate,
  getApplicationId,
  generateSecretKey
};

export default Lib;
