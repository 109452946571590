// api.ts

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Lib from 'utils'; 

const secretKey = Lib.generateSecretKey();

const encryptedBaseUrl = Lib.encryptBaseUrl('https://api.allgoodnasiya.uz/application/', secretKey);

const decryptedBaseUrl = Lib.decryptBaseUrl(encryptedBaseUrl, secretKey);

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: decryptedBaseUrl }),
  tagTypes: ['Identify'],
  endpoints: (builder) => ({
    identify: builder.mutation({
      query: ({ body }) => ({
        url: 'identify',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
        body: body,
      }),
      invalidatesTags: ['Identify'],
    }),
  }),
});

export const { useIdentifyMutation } = api;
export default api;
