import CryptoJS from 'crypto-js';

export const generateSecretKey = (length = 32) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let key = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    key += charset[randomIndex];
  }
  return key;
};

export const encryptBaseUrl = (url: string, secretKey: string) => {
  return CryptoJS.AES.encrypt(url, secretKey).toString();
};

export const decryptBaseUrl = (encryptedUrl: string, secretKey: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedUrl, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};