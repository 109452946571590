import styled from "styled-components";

export const ExpecstationdWrapper = styled.div`
  && {
    h3 {
      font-size: 50px;
      text-align: center;
      color: rgb(254, 150, 50);
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
    button {
      width: fit-content;
      margin: 0 auto;
      padding: 10px 40px;
      display: flex;
      text-align: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid rgb(254, 150, 50);
      background-color: rgb(254, 150, 50);
      color: white;
      cursor: pointer;
      font-size: 20px;
      opacity: 1; // Default opacity
      &:disabled {
        opacity: 0.4; // Opacity when the button is disabled
        cursor: not-allowed; // Optional: change cursor style for disabled button
      }
    }
  }
`
