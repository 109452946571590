import React, { useState } from 'react';
import { WrapperLanguage } from './languageComponent.s';
import FlagUz from "../../assets/flags/uz.svg";
import FlagRu from "../../assets/flags/ru.svg";
import FlagEn from "../../assets/flags/en.svg";
import { useTranslation } from 'react-i18next';

type Props = {};

const LanguageComponent = (props: Props) => {
    const { t, i18n } = useTranslation();
    function LanguValue() {
        return window.localStorage.getItem("i18nextLng")
      }
    const [show , setShow] = useState(LanguValue())
    const handleLang = (e) =>{
        const lang = e.target.id
        i18n.changeLanguage(lang)
        setShow(lang)
      }

  return (
    <WrapperLanguage>
      <button id={"uz"} className={show === 'uz'  ? 'active' : undefined} onClick={handleLang}>
        <img id={"uz"}  src={FlagUz as unknown as string} width={30} height={30} alt="FlagUz" />
      </button>
      <button id={"ru"} className={show === 'ru'  ? 'active' : undefined} onClick={handleLang} >
      <img id={"ru"} src={FlagRu as unknown as string} width={30} height={30} alt="FlagRu" />
      </button>
      <button id={"en"} className={show === 'en'  ? 'active' : undefined} onClick={handleLang}>
      <img id={"en"} src={FlagEn as unknown as string} width={30} height={30} alt="FlagEn" />
      </button>
    </WrapperLanguage>
  );
};

export default LanguageComponent;
