export const formatPhoneNumber = (input:string) => {
    const cleaned = input.replace(/[^\d+]/g, '');
  
    const phoneRegex = /^\+998\s?(\d{2})-?(\d{3})-?(\d{2})-?(\d{2})$/;
  
    const match = cleaned.match(phoneRegex);
  
    if (match) {
      return `+998 ${match[1]}-${match[2]}-${match[3]}-${match[4]}`;
    } else {
      return null;
    }
  }
  