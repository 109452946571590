import React from 'react'
import { Wrapper, WrapperLoading } from './index.s'
import CircularProgress from '@mui/material/CircularProgress';

type Props = {}

const LazyLoading = (props: Props) => {
  return (
    <Wrapper>
    <WrapperLoading>
        <CircularProgress />
    </WrapperLoading>
    </Wrapper>

  )
}

export default LazyLoading