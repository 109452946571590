import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import NewOrder from "./components/index";
import MyIdIframe from "components/my-id";
import CardInformation from "components/card-information";
import Otp from "components/otp";
import Passport from "components/my-id/passport-verfiy/passport";
import { Provider } from "react-redux";
import { store } from "store/store";
import ErrorLogice from "components/error-logice";
import { useTranslation } from "react-i18next";
import OtpBank1 from "components/otp-bank-1";
import OtpBank2 from "components/otp-bank-2";
import TokenError from "components/token-error";
import ErrorServer from "components/error-server";
import LazyLoading from "components/lazy-loading";
import Finish from "components/finish/finish";

function App() {
  const [props, setProps] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [timeoutError, setTimeoutError] = useState(false);
  const { t } = useTranslation();

  if (process.env.NODE_ENV !== "production") {
    console.error = () => {};
    console.warn = () => {};
  }

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.props) {
        setProps(event.data.props);
        setIsLoading(false);
      }
    };

    window.addEventListener("message", handleMessage);
    window.parent.postMessage("requestProps", "*");

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  
  useEffect(() => {
    if (props.broker_key) {
      const body = {
        broker_key: props.broker_key,
      };
      const login = async () => {
        setIsLoading(true);
        setIsError(false);
        setTimeoutError(false);
        try {
          const controller = new AbortController();
          const id = setTimeout(() => controller.abort(), 5000); // 5 seconds timeout

          const response = await fetch(
            "https://api.allgoodnasiya.uz/auth/broker-login",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
              signal: controller.signal,
            }
          );

          clearTimeout(id);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const result = await response.json();
          setData(result);
          localStorage.setItem("accessToken", result.result.access_token);
        } catch (error) {
          console.error("Failed to create Login:", error);
          if (error.name === "AbortError") {
            setTimeoutError(true);
          } else {
            setIsError(true);
          }
        } finally {
          setIsLoading(false);
        }
      };

      login();
    }
  }, [props]);



  if (isLoading && !isError && props.broker_key) {
    return <LazyLoading />;
  }

  if (timeoutError) {
    return <ErrorServer />;
  }

 if (isError || !props.broker_key) {
    return <ErrorLogice />;
  }

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/card-info" />} />
          <Route path="/card-info" element={<CardInformation />} />
          <Route path="/sms/:application_id" element={<Otp />} />
          <Route path="/identification/:application_id" element={<Passport />} />
          <Route path="/identification-face-id/" element={<MyIdIframe broker={props.broker} />} />
          <Route path="/otp-bank1/:application_id" element={<OtpBank1 />} />
          <Route path="/otp-bank2/:application_id" element={<OtpBank2 />} />
          <Route path="/finish/:application_id" element={<Finish />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
