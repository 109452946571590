import { MuiOtpInput } from "mui-one-time-password-input";
import styled from "styled-components";

export const MyIdWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  h2 {
    color: rgba(47, 43, 61, 0.78);
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    text-align: center;
    color: gray;
    opacity: 0.7;
    margin-bottom: 10px;
  }
  span {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    color: red;
    margin-top: 10px;
  }
  .button-send {
    display: flex;
    width: fit-content;
    align-items: center;
    height: fit-content;
    margin-top: auto;
    background-color: rgb(254 150 50);
    box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
    color: white;
    border: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding: 10px 25px;
    border-radius: 10px;
    cursor: pointer;
    :disabled {
      opacity: 0.5 !important;
      cursor: pointer;
    }
    :hover {
      background-color: rgb(254 150 50) !important;
      box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px !important;
    }
    @media screen and (max-width: 1024px) {
      margin: 0 auto;
      margin-top: 20px;
    }
  }
  .button-close {
    display: flex;
    width: fit-content;
    align-items: center;
    height: fit-content;
    margin-top: 20px;
    margin-right: auto;
    background-color: rgba(168, 170, 174, 0.16);
    box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
    color: rgb(168, 170, 174);
    border: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding: 10px 25px;
    border-radius: 10px;

    cursor: pointer;
    @media screen and (max-width: 1024px) {
      margin: 0 auto;
      margin-top: 20px;
    }
  }
  p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(254 150 50);
    margin-top: 10px;
  }
`;

export const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 30px;
  max-width: 410px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-inline: auto;
  .MuiOutlinedInput-input {
    border: 1px solid transparent !important;
    background-color: #f6f8fa;
    }
  @media screen and (max-width: 620px) {
    gap: 6px;
    .MuiOutlinedInput-input {
        padding: 9.5px 14px !important;
    }
}
@media screen and (max-width: 400px) {
    gap: 6px;
    .MuiOutlinedInput-input {
      padding: 5.5px 8px !important;
    }
  }
`;
