import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { WrapperPassport } from "./Passport.s";
import CachedIcon from "@mui/icons-material/Cached";
import { handlePassportInput } from "utils/passpor-seria";
import { handlePassportPnflInput } from "utils/pnfl-seria";
import { useNavigate, useParams } from "react-router-dom";
import NewOrder from "components";
import { useTranslation } from "react-i18next";
import { BorderLinearProgress, WrapperTop } from "components/card-information/index.s";

const passportRegex = /^[A-Z]{2}\d{7}$/;
const passportRegexPnfl = /^[A-Z0-9]{14}$/;

function Passport() {
  const [isPassportNumber, setIsPassportNumber] = useState(true);
  const { application_id } = useParams();
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    passportNumber: Yup.string()
      .matches(
        isPassportNumber ? passportRegex : passportRegexPnfl,
        isPassportNumber ? t("PassportVerify.0") : t("PassportVerify.1")
      )
      .required(
        isPassportNumber ? t("PassportVerify.2") : t("PassportVerify.3")
      ),
    birthDate: Yup.date().required(t("PassportVerify.4")).nullable(),
  });
  const navigate = useNavigate();
  return (
    <NewOrder>
      <WrapperTop>
        <h3>Идентификация</h3>
        <h5>Введите свои паспортные данные</h5>
        <BorderLinearProgress variant="determinate" value={100} />
      </WrapperTop>
      <WrapperPassport>
        <Formik
          initialValues={{
            passportNumber: "",
            birthDate: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await navigate(
              `/identification-face-id?passportNumber=${values.passportNumber}&birthDate=${values.birthDate}&application_id=${application_id}`
            );
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <div>
                <label htmlFor="passportField">
                  {isPassportNumber
                    ? t("PassportVerify.5")
                    : t("PassportVerify.6")}
                </label>
                {isPassportNumber ? (
                  <Field
                    name={"passportNumber"}
                    type="text"
                    placeholder={"__ _ _ _ _ _ _ _ _"}
                    onChange={(event) =>
                      handlePassportInput(event, setFieldValue)
                    }
                  />
                ) : (
                  <Field
                    name={"passportNumber"}
                    type="text"
                    placeholder={"_ _ _ _ _ _ _ _ _ _ _ _ _ _"}
                    onChange={(event) =>
                      handlePassportPnflInput(event, setFieldValue)
                    }
                  />
                )}

                <ErrorMessage
                  name={"passportNumber"}
                  component="div"
                  className="error"
                />
                <span onClick={() => setIsPassportNumber((prev) => !prev)}>
                  <CachedIcon /> {t("PassportVerify.10")}{" "}
                  {isPassportNumber
                    ? t("PassportVerify.6")
                    : t("PassportVerify.7")}
                </span>
              </div>
              <div>
                <label htmlFor="birthDate">{t("PassportVerify.8")}</label>
                <Field
                  name="birthDate"
                  type="date"
                  onChange={(event) =>
                    setFieldValue("birthDate", event.target.value)
                  }
                />
                <ErrorMessage
                  name="birthDate"
                  component="div"
                  className="error"
                />
              </div>
              <button type="submit">{t("PassportVerify.9")}</button>
            </Form>
          )}
        </Formik>
      </WrapperPassport>
    </NewOrder>
  );
}

export default Passport;
