export default function DateFormate(dateString: string, dataMonthYear: boolean) {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    switch (dataMonthYear) {
      case true:
        return `${month}.${year}`;
      case false:
        return `${day}.${month}.${year}`;
      default:
        throw new Error("Invalid value for dataMonthYear");
    }
  }
  