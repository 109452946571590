import styled from '@emotion/styled';

export const WrapperPassport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 400px;
  margin: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width:900px) {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div {
    margin-bottom: 8px;
    width: 100%;
  }
  .error{
    font-size: 6px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
    transform: none;
    position: relative;
    margin-bottom: 0.25rem;
    font-size: 16px;
    color: rgba(47, 43, 61, 0.78) !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.154;
  }
  span{
    color: orange;
    display: flex;
    font-weight: 600;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    margin-top: 5px;
    cursor: pointer;
    svg{
      width: 18px;
      height: 18px;
      margin-right: 3px;
    }
  }
  input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #F6F8FA !important;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid rgba(47, 43, 61, 0.28);
    padding: 13px 10px;
    border-radius: 7px;
    font-size: 16px;
    color: rgba(47, 43, 61, 0.78) !important;
    background-color: #F6F8FA;
    font-family: Arial, Helvetica, sans-serif; 
  }
  

  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }

  button {
    background-color: #F98329;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
