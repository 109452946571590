import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpecstationdWrapper } from './Expectation.s';
import { useLocation, useNavigate } from 'react-router-dom';
type Props = {}

const Expectation = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const application_id = queryParams.get("");
  const [timeLeft, setTimeLeft] = useState(10); // Initial countdown in seconds
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <ExpecstationdWrapper>
      <h2>{t("OtbBank1.2")}</h2>
      <h5>{t("OtbBank1.1")}</h5>
      <h3>{formatTime(timeLeft)} </h3>
      <button 
        disabled={isButtonDisabled}
        onClick={() => navigate(`/otp-bank2/${application_id}`)}
      >
        {t("OtbBank1.3")}
      </button>
    </ExpecstationdWrapper>
  );
}

export default Expectation;
